import React from 'react'



const MapSection = () => {
  return (
    <div class="mapouter">
        <div style={{backgroundColor:"#292929", paddingTop:"50px"}}
        class="gmap_canvas">
       <iframe width="800" height="400" id="gmap_canvas"  src="https://maps.google.com/maps?q=Dimitrija%20Tucovica%20196a%20Nis&t=&z=14&ie=UTF8&iwloc=&output=embed"  scrolling="no" >
        </iframe>
        <a href="https://putlocker-is.org"></a>
    </div>
    </div>
  )
}

export default MapSection


